import * as THREE from 'three';
import TrajectoryTracker from './targetTrajectory.js';

const GameState = {
    NOT_STARTED: 'not_started',
    PLAYING: 'playing',
    COMPLETED: 'completed'
};

class LevelTargetTrajectory {
    constructor(scene, modalPanelId, levelCompleteModalId, scoreDisplayId, timerDisplayId, startButtonId) {
        this.scene = scene;
        this.modalPanel = document.getElementById(modalPanelId);
        this.levelCompleteModal = document.getElementById(levelCompleteModalId);
        this.scoreDisplay = document.getElementById(scoreDisplayId);
        this.timerDisplay = document.getElementById(timerDisplayId);
        this.startButton = document.getElementById(startButtonId);
        this.startPoint = new THREE.Vector3(-12, 0, 0);
        this.endPoint = new THREE.Vector3(-4, 0, 0);
        this.numPoints = 10;
        this.targetTrajectory = new TrajectoryTracker([], []);
        this.updateInterval = null;
        this.numTries = 0; // Number of attempts.
        this.state = GameState.NOT_STARTED; // Initialize the state
        this.currentLevel = 2;
    }

    initTrajectory() {
        this.targetTrajectory.removeTrajectoryFromScene(this.scene);
        switch (this.currentLevel) {
            case 0:
                this.targetTrajectory.generateLinearTrajectory(this.startPoint, this.endPoint, this.numPoints);
                break;
            case 1:
                const startPointLevel1 = new THREE.Vector3(this.startPoint.x, this.startPoint.y - 1, this.startPoint.z);
                const endPointLevel1 = new THREE.Vector3(this.endPoint.x, this.endPoint.y - 1, this.endPoint.z);
                this.targetTrajectory.generateLinearTrajectory(startPointLevel1, endPointLevel1, this.numPoints);
                break;
            case 2:
                const startPointLevel2 = new THREE.Vector3(this.startPoint.x, this.startPoint.y - 0.5, this.startPoint.z);
                const endPointLevel2 = new THREE.Vector3(this.endPoint.x, this.endPoint.y + 0.5, this.endPoint.z);
                this.targetTrajectory.generateLinearTrajectory(startPointLevel2, endPointLevel2, this.numPoints);                break;
            case 3:
                const startPointLevel3 = new THREE.Vector3(this.startPoint.x, this.startPoint.y + 0.5, this.startPoint.z);
                const endPointLevel3 = new THREE.Vector3(this.endPoint.x, this.endPoint.y - 0.5, this.endPoint.z);
                this.targetTrajectory.generateLinearTrajectory(startPointLevel3, endPointLevel3, this.numPoints);   
                break;
            default:
                console.error(`Unknown level: ${this.currentLevel}`);
                break;
        }
        this.targetTrajectory.addTrajectoryToScene(this.scene);
    }

    setLevel(level) {
        this.currentLevel = level;
        this.initTrajectory(); // Re-initialize the trajectory for the new level
        this.updateUI(); // Update the UI to reflect the new level
    }

    getCurrentLevel() {
        return this.currentLevel;
    }

    showStartGameModal() {
        this.modalPanel.style.display = 'block';
    }

    hideStartGameModal() {
        this.modalPanel.style.display = 'none';
    }


    showLevelCompleteModal() {
        const levelTitle = document.getElementById('levelCompleteModalTitle');
        if (levelTitle) {
            levelTitle.textContent = `Level ${this.currentLevel} Complete`;
        }
        const finalScoreDisplay = document.getElementById('finalScoreDisplay');
        const totalTimeDisplay = document.getElementById('totalTimeDisplay');
        if (finalScoreDisplay && totalTimeDisplay) {
            const score = this.targetTrajectory.getScore();
            finalScoreDisplay.textContent = score.finalScore.toFixed(1);
            totalTimeDisplay.textContent = `${score.totalTime.toFixed(2)}s`;
        }
        this.levelCompleteModal.style.display = 'block';
    }

    hideLevelCompleteModal() {
        this.levelCompleteModal.style.display = 'none';
    }

    isPlaying() {
        return this.state === GameState.PLAYING;
    }

    startGame() {
        this.numTries += 1;
        if (this.numTries <= 2) {
            this.showStartGameModal();
        } else {
            clearInterval(this.updateInterval);
            this.state = GameState.PLAYING;
            this.updateUI();
            this.targetTrajectory.start();
            this.updateScoreAndTimer();
        }
    }

    endGame() {
        clearInterval(this.updateInterval);
        this.state = GameState.COMPLETED;
        this.updateUI();
        this.showLevelCompleteModal();
    }

    closeModalAndStart() {
        clearInterval(this.updateInterval);
        this.state = GameState.PLAYING;
        this.updateUI();
        this.targetTrajectory.start();
        this.hideStartGameModal();
        this.updateScoreAndTimer();  
    }

    updateScoreAndTimer() {
        this.updateInterval = setInterval(() => {
            if (this.targetTrajectory.isCompleted()) {
                clearInterval(this.updateInterval);
                this.state = GameState.COMPLETED;
                this.updateUI();
                const score = this.targetTrajectory.getScore();
                this.scoreDisplay.innerText = `${score.finalScore.toFixed(1)}`;
                this.timerDisplay.innerText = `${score.totalTime.toFixed(2)}s`;
            } else {
                const currentTime = (performance.now() - this.targetTrajectory.startTime) / 1000;
                this.timerDisplay.innerText = `${currentTime.toFixed(0)}s`;
                this.scoreDisplay.innerText = `${this.targetTrajectory.matchedPoints}`;
            }
        }, 1000);
    }

    updateUI() {
        switch (this.state) {
            case GameState.NOT_STARTED:
                this.startButton.innerText = 'Start Level';
                break;
            case GameState.PLAYING:
                this.startButton.innerText = 'Restart Level';
                break;
            case GameState.COMPLETED:
                this.startButton.innerText = 'Retry Level';
                break;
        }
    }
}

export default LevelTargetTrajectory;