// This loads and updates the model for the PC120 excavator.
// This is the model from: 

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { ExcavatorBaseClass } from './ExcavatorBaseClass.js';

import ExcavatorGLB from 'url:./assets/models/ExcavatorV2Simple_Adriano.glb'

export class Excavator330B extends ExcavatorBaseClass {
    constructor(scene) {
        super();
        this.scene = scene;
        this.excavator = null;
        this.undercarriage = null;
        this.torso = null;  // Also called House, Upper Strucure, Superstructure.
        this.torsoBone = null;
        this.boom = null;
        this.boomBone = null;
        this.stick = null;  // Sometimes called arm, which is a misnomer.
        this.stickBone = null;
        this.bucketBone = null;
        this.bucketTip = null;
        this.controlMode = 'ISO'; // Default control mode
        this.modelLoaded = false;
    }

    loadModel() {
        const loader = new GLTFLoader();
        loader.load(ExcavatorGLB, (gltf) => {
            this.excavator = gltf.scene;
            this.torso = this.excavator.getObjectByName('Base1'); // Mesh excavator body.
            this.torsoBone = this.excavator.getObjectByName('Armature'); // Base bone.
            this.boom = this.excavator.getObjectByName('ArmA'); // Mesh boom
            this.boomBone = this.excavator.getObjectByName('Arm1Joint'); // Bone
            this.stick = this.excavator.getObjectByName('Arm_B'); // Mesh stick
            this.stickBone = this.excavator.getObjectByName('Arm2Joint'); // Bone
            this.bucket = this.excavator.getObjectByName('BucketMain'); // Mesh
            this.bucketBone = this.excavator.getObjectByName('BucketJoint1'); // Bone
            this.bucketTipMarker = this.excavator.getObjectByName('BucketTipMarker'); // Object.

            this.scene.add(this.excavator);
            this.modelLoaded = true;  // Animation loop starts before models are fully loaded.
        });
    }

    updateExcavatorPose(boomControl, stickControl, bucketControl, swingControl) {
        if (Math.abs(swingControl) > 0) {
            // Use the swingControl to control the base rotation
            let newRotationYBase = this.torso.rotation.y + 0.0005 * -swingControl;
            this.torso.rotation.y = newRotationYBase;
        }

        if (Math.abs(boomControl) > 0) {
            // Use the boomControl to control the boom (Arm1)
            let newRotationZArm1 = Math.max(-3.1, Math.min(-1.5, this.boomBone.rotation.z + 0.0005 * boomControl));
            this.boomBone.rotation.z = newRotationZArm1;
        }

        if (Math.abs(stickControl) > 0) {
            // Use the stickControl to control Arm2
            let newRotationZArm2 = Math.max(-2.9, Math.min(-1, this.stickBone.rotation.z + 0.0005 * stickControl));
            this.stickBone.rotation.z = newRotationZArm2;
        }

        if (Math.abs(bucketControl) > 0) {
            // Use the bucketControl to control the bucket rotation
            let newRotationZBucket = Math.max(-1.17, Math.min(2.28, this.bucketBone.rotation.z + 0.001 * -bucketControl));
            this.bucketBone.rotation.z = newRotationZBucket;
        }
        console.log('updating excavator pose');
    }

    switchControlModeTo(mode) {
        this.controlMode = mode;
    }

    setZeroReferenceLevelFromBucket() {
        if (this.bucketTipMarker) {
            const bucketTipPosition = new THREE.Vector3();
            this.bucketTipMarker.getWorldPosition(bucketTipPosition);
            this.zeroReferenceLevel = bucketTipPosition.y;
        }
    }

    computeAndLogLengths() {
    
        function normalizeRadians(angle) {
            angle = angle % (2 * Math.PI); // Normalize the angle to the range -2pi to 2pi
            if (angle > Math.PI) {
                angle -= 2 * Math.PI; // Adjust to the range -pi to pi if the angle is greater than pi
            } else if (angle <= -Math.PI) {
                angle += 2 * Math.PI; // Adjust to the range -pi to pi if the angle is less than or equal to -pi
            }
            return angle;
        }
    
        // Assuming that the pivot points are the positions of the respective joints
        boomLength = arm1Joint.position.distanceTo(arm2Joint.position);
        stickLength = arm2Joint.position.distanceTo(bucketJoint1.position);
        bucketLength = bucketJoint1.position.distanceTo(bucketTipMarker.position);
    
        console.log(`Boom Length: ${boomLength.toFixed(2)} units`);
        console.log(`Stick Length: ${stickLength.toFixed(2)} units`);
        console.log(`Bucket Length: ${bucketLength.toFixed(2)} units`);
    
        const boomHinge = new THREE.Vector3();
        arm1Joint.getWorldPosition(boomHinge);
        console.log(`Boom hinge position: x=${boomHinge.x.toFixed(2)}, y=${boomHinge.y.toFixed(2)}, z=${boomHinge.z.toFixed(2)}`);
    
        const stickHinge = new THREE.Vector3();
        arm2Joint.getWorldPosition(stickHinge);
        console.log(`Stick hinge position: x=${stickHinge.x.toFixed(2)}, y=${stickHinge.y.toFixed(2)}, z=${stickHinge.z.toFixed(2)}`);
    
        const bucketHinge = new THREE.Vector3();
        bucketJoint1.getWorldPosition(bucketHinge);
        console.log(`Bucket hinge position: x=${bucketHinge.x.toFixed(2)}, y=${bucketHinge.y.toFixed(2)}, z=${bucketHinge.z.toFixed(2)}`);
    
        // Compute the boom angle (from the boom hinge to the stick hinge)
        const boomDirection = new THREE.Vector3().subVectors(stickHinge, boomHinge);
        console.log(`Boom direction: x=${boomDirection.x.toFixed(2)}, y=${boomDirection.y.toFixed(2)}, z=${boomDirection.z.toFixed(2)}`);
        boomActualAngle = Math.atan2(boomDirection.y, -boomDirection.x);
        console.log(`Boom angle: ${THREE.MathUtils.radToDeg(boomActualAngle).toFixed(2)} degrees`);
    
        // Compute the stick angle (from the stick hinge to the bucket hinge)
        const stickDirection = new THREE.Vector3().subVectors(bucketHinge, stickHinge);
        console.log(`Stick direction: x=${stickDirection.x.toFixed(2)}, y=${stickDirection.y.toFixed(2)}, z=${stickDirection.z.toFixed(2)}`);
        stickActualAngle = Math.atan2(stickDirection.y, -stickDirection.x);
        console.log(`Stick angle: ${THREE.MathUtils.radToDeg(stickActualAngle).toFixed(2)} degrees`);
    
        // Compute the bucket angle (from the bucket hinge to the bucket tip marker)
        const bucketTipWorldPosition = new THREE.Vector3();
        bucketTipMarker.getWorldPosition(bucketTipWorldPosition);
        const bucketDirection = new THREE.Vector3().subVectors(bucketTipWorldPosition, bucketHinge);
        console.log(`Bucket direction: x=${bucketDirection.x.toFixed(2)}, y=${bucketDirection.y.toFixed(2)}, z=${bucketDirection.z.toFixed(2)}`);
        bucketActualAngle = Math.atan2(bucketDirection.y, bucketDirection.x);
        console.log(`Bucket angle: ${THREE.MathUtils.radToDeg(bucketActualAngle).toFixed(2)} degrees`);
    
        const arm1JointRotationZ = arm1Joint.rotation.z;
        const arm2JointRotationZ = arm2Joint.rotation.z;
        const bucketJoint1RotationZ = bucketJoint1.rotation.z;
    
        console.log(`Arm1 Joint Rotation Z: ${THREE.MathUtils.radToDeg(arm1JointRotationZ).toFixed(2)} degrees`);
        console.log(`Arm2 Joint Rotation Z: ${THREE.MathUtils.radToDeg(arm2JointRotationZ).toFixed(2)} degrees`);
        console.log(`Bucket Joint1 Rotation Z: ${THREE.MathUtils.radToDeg(bucketJoint1RotationZ).toFixed(2)} degrees`);
    
        boomOffset = normalizeRadians(arm1JointRotationZ - boomActualAngle);
        stickOffset = normalizeRadians(arm2JointRotationZ - stickActualAngle);
        bucketOffset = normalizeRadians(bucketJoint1RotationZ - bucketActualAngle);
    
        console.log(`Boom Offset: ${THREE.MathUtils.radToDeg(boomOffset).toFixed(2)} degrees`);
        console.log(`Stick Offset: ${THREE.MathUtils.radToDeg(stickOffset).toFixed(2)} degrees`);
        console.log(`Bucket Offset: ${THREE.MathUtils.radToDeg(bucketOffset).toFixed(2)} degrees`);
    
        arm1Joint.rotation.z = boomOffset;
    
        initialAnglesCaptured = true;
    }
}
