import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js';  // Used for terrain.
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js';
import HelveticaFont from './assets/fonts/helvetiker_bold.typeface.json'; 
import nipplejs from 'nipplejs';
import sandPointCloud from 'url:./assets/models/SandPointCloud473.ply';
import SandTexture1 from 'url:./assets/textures/SandTextureGray.jpeg';
import LevelTargetTrajectory from './levelTargetTrajectory.js';
import { Excavator330B } from './Excavator330B.js';
import { ExcavatorPC120 } from './ExcavatorPC120.js';
import { ExcavatorLowPoly } from './ExcavatorLowPoly.js';

// Constants for the initial sequence.
const introInitialPause = 1000; 
const introFlightDuration = 5000;

let scene, camera, renderer, controls;
let orthographicCamera, perspectiveCamera;
let cameraHelper;
let useOrthographicCamera = false;
let excavator; // Updated to use Excavator330B class
let groundPlane, safetyBox, terrain;
let leftJoystick, rightJoystick;
let leftJoystickData = { distance: 0, angle: { radian: 0 } };
let rightJoystickData = { distance: 0, angle: { radian: 0 } };
let gamepad;
let zeroReferenceLevel = 0.0; // Added variable to store the zero reference level
let gridHelper;
let lastButton0State = 0; // Debonce gamepad button.
let controlMode = 'ISO'; // Default control mode
let isScannedTerrainVisible = true; // Variable to toggle point cloud visibility

let boomActualAngle, stickActualAngle, bucketActualAngle;
let boomLength, stickLength, bucketLength;

let levelTargetTrajectory;

let logoCenterPoint = new THREE.Vector3(5, 3, 10); // Center of the logo, for placement and lookAt.
let textMesh;
let excavatorMesh;  // hack I should combine these.
let lastCallTime = Date.now();  // for animation path interpolation.

let gameMode = false;

function switchControlModeTo(mode) {
    controlMode = mode;
}

document.querySelectorAll('input[name="controlPattern"]').forEach(radio => {
    radio.addEventListener('change', function(event) {
        if (event.target.checked) {
            switchControlModeTo(event.target.value);
        }
    });
});

document.querySelectorAll('input[name="excavatorSelection"]').forEach(radio => {
    radio.addEventListener('change', function(event) {
        if (event.target.checked) {
            switchExcavatorTo(event.target.value);
        }
    });
});

function switchExcavatorTo(excavatorType) {
    console.log(`Switching excavator to: ${excavatorType}`);
    switch (excavatorType) {
        case 'Simple':
            console.log('Initializing Simple Excavator model');
            excavator.deleteExcavator();
            excavator = new ExcavatorLowPoly(scene);
            excavator.loadModel();
            console.log('Simple Excavator model loaded');
            break;
        case 'CAT330B':
            console.log('Initializing CAT 330B Excavator model');
            excavator.deleteExcavator();
            excavator = new Excavator330B(scene);
            excavator.loadModel();
            console.log('CAT 330B Excavator model loaded');
            break;
        case 'KomatsuPC120':
            console.log('Initializing Komatsu PC120 Excavator model');
            excavator.deleteExcavator();
            excavator = new ExcavatorPC120(scene);
            excavator.loadModel();
            console.log('Komatsu PC120 Excavator model loaded');
            break;
        default:
            console.error(`Unknown excavator type: ${excavatorType}`);
    }
    // Assuming there's a function to add the excavator to the scene
    // addExcavatorToScene(excavator);
    console.log('Excavator switched and added to the scene');
}


document.getElementById('perspectiveCamera').addEventListener('change', function(event) {
    if (event.target.checked) {
        camera = perspectiveCamera;
        controls.object = camera;
        if (cameraHelper) {
            cameraHelper.visible = false;
        }
    }
});

document.getElementById('orthographicCamera').addEventListener('change', function(event) {
    if (event.target.checked) {
        camera = orthographicCamera;
        controls.object = camera;
        if (cameraHelper) {
            cameraHelper.visible = true;
        }
    }
});

document.getElementById('terrainVisible').addEventListener('change', function(event) {
    if (terrain) {
        terrain.visible = event.target.checked;
    } else {
        loadTerrain();
    }
});

document.getElementById('terrainHidden').addEventListener('change', function(event) {
    if (terrain) {
        terrain.visible = !event.target.checked;
    }
});

document.getElementById('startPlayButton').addEventListener('click', function() {
    levelTargetTrajectory.startGame();
});

document.getElementById('startGameModal_StartButton').addEventListener('click', function() {
    levelTargetTrajectory.closeModalAndStart();
});

document.getElementById('levelComplete_TryAgainButton').addEventListener('click', function() {
    levelTargetTrajectory.hideLevelCompleteModal();
    levelTargetTrajectory.startGame();
});

document.getElementById('levelComplete_NextLevelButton').addEventListener('click', function() {
    levelTargetTrajectory.hideLevelCompleteModal();
    levelTargetTrajectory.setLevel(1 + levelTargetTrajectory.currentLevel);
    levelTargetTrajectory.startGame();
});   

document.addEventListener('DOMContentLoaded', (event) => {
    init();
});

if (!gameMode) {
  document.getElementById('GameStatsPanel').style.display = 'none';
}



function init() {
    // Scene setup
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff); // Set the background to a lighter blue (Powder Blue)

    // Perspective Camera
    perspectiveCamera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    perspectiveCamera.position.set(5, 5, 10);
    
    // Orthographic Camera
    const aspect = window.innerWidth / window.innerHeight;
    const frustumSize = 20;
    orthographicCamera = new THREE.OrthographicCamera(frustumSize * aspect / -2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / -2, 1, 1000);
    orthographicCamera.position.set(0, 0, 10);
    orthographicCamera.lookAt(scene.position);

    // Camera Helper
    cameraHelper = new THREE.CameraHelper(orthographicCamera);
    cameraHelper.visible = false;
    scene.add(cameraHelper);

    // Set initial camera
    camera = perspectiveCamera;

    // Renderer
    renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    const sceneContainer = document.getElementById('3dSceneContainer');
    sceneContainer.appendChild(renderer.domElement);

    // Orbit Controls
    controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    controls.dampingFactor = 0.25;
    controls.screenSpacePanning = false;

    // Hemisphere Light
    const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 2);
    hemiLight.position.set(0, 50, 0);
    scene.add(hemiLight);

    // Directional Light
    const dirLight = new THREE.DirectionalLight(0xffffff, 3);
    dirLight.position.set(2, 8, 16);
    dirLight.castShadow = true;
    dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
    const d = 10;
    dirLight.shadow.camera.left = -d;
    dirLight.shadow.camera.right = d;
    dirLight.shadow.camera.top = d;
    dirLight.shadow.camera.bottom = -d;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 200;
    dirLight.shadow.mapSize.set(1024, 1024);
    scene.add(dirLight);

    // Loaders and Geometry
    // loadTerrain();
    //createGroundPlane();
    createGridHelper();

    // Excavision suport. This is the red plane to dig to.
    // createSafetyBox();

    createTextMeshes();

    // Load Excavator Model using Excavator330B class
    console.log('constructing excavator');
    //excavator = new ExcavatorLowPoly(scene);
    excavator = new Excavator330B(scene);
    //excavator = new ExcavatorPC120(scene);
    excavator.loadModel();

    if (gameMode) {
      levelTargetTrajectory = new LevelTargetTrajectory(scene, 'startGameModal', 'levelCompleteModal', 'currentScoreDisplay', 'elapsedTimeDisplay', 'startPlayButton');
      levelTargetTrajectory.initTrajectory();
    }
 
    // Camera position
    camera.position.set(-3 , 5, 10);

    // Joysticks
    createJoysticks();

    // Gamepad setup
    setupGamepad();

    // Button event handlers for excavision.
    //setupExcavisionEventHandlers();

    // Controls
    document.addEventListener('keydown', onDocumentKeyDown, false);

    // Mouse events
    setupMouseEvents();

    animate();



}

function createSkybox() {
    const loader = new THREE.CubeTextureLoader();
    const texture = loader.load([
        'path/to/px.jpg', // positive x
        'path/to/nx.jpg', // negative x
        'path/to/py.jpg', // positive y
        'path/to/ny.jpg', // negative y
        'path/to/pz.jpg', // positive z
        'path/to/nz.jpg', // negative z
    ]);
    scene.background = texture;
}

function createTextMeshes() {
    const loader = new FontLoader();
    console.log('Attempting to load font:', HelveticaFont);
    // Load a font and create text geometries with the font
    loader.load(HelveticaFont, function (font) {
        const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });

        const fontSize = 1;
        const depthRatio = 0.1;
        const excavatorTextGeometry = new TextGeometry('EXCAVATOR', {
            font: font,
            size: fontSize,
            height: fontSize * depthRatio
        });

        const simDepthRatio = 0.3;
        const simTextGeometry = new TextGeometry('SIM', {
            font: font,
            size: fontSize,
            height: fontSize * simDepthRatio
        });

        // Adjust the size of 'SIM' to match the width of 'EXCAVATOR'
        const excavatorBox = new THREE.Box3().setFromObject(new THREE.Mesh(excavatorTextGeometry));
        const simBox = new THREE.Box3().setFromObject(new THREE.Mesh(simTextGeometry));
        const simScaleFactor = excavatorBox.getSize(new THREE.Vector3()).x / simBox.getSize(new THREE.Vector3()).x;
        simTextGeometry.scale(simScaleFactor, simScaleFactor / 2, 1);

        // Create mesh objects for the text
        excavatorMesh = new THREE.Mesh(excavatorTextGeometry, textMaterial);
        const simMesh = new THREE.Mesh(simTextGeometry, textMaterial);

        const zPosition = 10;
        const rotation = 0; 
        // Position and rotate the text above the excavator
        excavatorMesh.position.set(5, 2.5, zPosition); // Adjust position as needed
        excavatorMesh.rotation.y = THREE.MathUtils.degToRad(-rotation); // Rotate by the rotation angle in degrees
        simMesh.position.set(5, 0, zPosition); // Adjust position as needed
        simMesh.rotation.y = THREE.MathUtils.degToRad(-rotation); // Rotate by the rotation angle in degrees

        textMesh = simMesh;
        // Add the text to the scene
        scene.add(excavatorMesh);
        scene.add(simMesh);

        introSequence();
    });
}

function loadTerrain() {
    const plyLoader = new PLYLoader();
    plyLoader.load(sandPointCloud, function (geometry) {
        geometry.computeVertexNormals();
        if (geometry.hasAttribute('color')) {
            const material = new THREE.PointsMaterial({
                size: 0.1,
                sizeAttenuation: true,
                vertexColors: true
            });
            terrain = new THREE.Points(geometry, material);
            terrain.scale.set(20, 20, 20);
            terrain.position.y += 7;
            terrain.position.x -= 2;
            terrain.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI / 2);
            terrain.visible = isScannedTerrainVisible;
            scene.add(terrain);
        } else {
            console.warn('The PLY file does not contain color attributes.');
        }
    });
}

function createGroundPlaneOld() {
    const groundGeometry = new THREE.PlaneGeometry(1000, 1000);
    const groundMaterial = new THREE.MeshLambertMaterial({ color: 0x9db3b5, transparent: true, opacity: 0.5 });
    groundPlane = new THREE.Mesh(groundGeometry, groundMaterial);
    groundPlane.rotation.x = -Math.PI / 2;
    groundPlane.receiveShadow = true;
    scene.add(groundPlane);
}

function createGroundPlane() {
    const boxGeometry = new THREE.BoxGeometry(100, 100, 100);
    const sandTexture = new THREE.TextureLoader().load(SandTexture1);
    sandTexture.wrapS = THREE.RepeatWrapping;
    sandTexture.wrapT = THREE.RepeatWrapping;
    sandTexture.repeat.set(20, 20);
    const boxMaterial = new THREE.MeshLambertMaterial({ map: sandTexture });
    const center = new THREE.Vector3(-3, 0, -3);
    const positions = [
        { x: center.x + 50, y: center.y - 50, z: center.z + 50 },
        { x: center.x - 50, y: center.y - 55, z: center.z + 50 },  // right handed coordinate system.
        { x: center.x + 50, y: center.y - 50, z: center.z - 50 },
        { x: center.x - 50, y: center.y - 50, z: center.z - 50 }
    ];

    positions.forEach(position => {
        const groundBox = new THREE.Mesh(boxGeometry, boxMaterial);
        groundBox.position.set(position.x, position.y, position.z);
        scene.add(groundBox);
    });
}


function createGridHelper() {
    gridHelper = new THREE.GridHelper(1000, 500);
    gridHelper.visible = false;
    scene.add(gridHelper);
}

function createSafetyBox() {
    const safetyBoxGeometry = new THREE.BoxGeometry(10, 0.05, 2.5);
    const safetyBoxMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0.5 });
    safetyBox = new THREE.Mesh(safetyBoxGeometry, safetyBoxMaterial);
    safetyBox.position.set(-5, -1, 0);
    scene.add(safetyBox);
}


let modelLoaded = false;

function setupGamepad() {
    window.addEventListener("gamepadconnected", function(e) {
        gamepad = navigator.getGamepads()[e.gamepad.index];
    });
}



function setupMouseEvents() {
    console.log('Setting up mouse events');
    const planeXY = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    const mouseDownPosition = new THREE.Vector2();

    renderer.domElement.addEventListener('mousedown', function(event) {
        console.log(`Mouse down at x: ${event.clientX}, y: ${event.clientY}`);
        mouseDownPosition.x = event.clientX;
        mouseDownPosition.y = event.clientY;
    });

    renderer.domElement.addEventListener('mouseup', function(event) {
        console.log(`Mouse up at x: ${event.clientX}, y: ${event.clientY}`);
        if (mouseDownPosition.x === event.clientX && mouseDownPosition.y === event.clientY) {
            console.log('Mouse click detected');
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.ray.intersectPlane(planeXY, new THREE.Vector3());
            if (intersects) {
                console.log(`Intersect found at x: ${intersects.x.toFixed(2)}, y: ${intersects.y.toFixed(2)}`);
                if (excavator && excavator.modelLoaded) {
                    excavator.moveBucketTo(intersects.x, intersects.y);
                }
            } else {
                console.log('No intersect found');
            }
        }
    });
}

function moveBucketToMouseCoordinate(point) {
    console.log(`Move bucket to: x=${point.x.toFixed(2)}, y=${point.y.toFixed(2)}`);
    // IK logic will be added here in the future

    function solveIK2D(x, y, l1, l2) {
        const d = Math.sqrt(x * x + y * y);
        console.log(`Lengths: l1=${l1}, l2=${l2}`);
        console.log(`Target position: x=${x}, y=${y}`);
    
        // Check if the target is reachable
        if (d > l1 + l2) {
            console.error("Target is out of reach");
            return null;
        }
    
        // Calculate the angles in radians
        const theta1Radians = Math.atan2(y, x) - Math.acos((l1 * l1 + d * d - l2 * l2) / (2 * l1 * d));
        const theta2Radians = Math.PI - Math.acos((l1 * l1 + l2 * l2 - d * d) / (2 * l1 * l2));
        // Convert the angles to degrees
        const theta1Degrees = THREE.MathUtils.radToDeg(theta1Radians);
        const theta2Degrees = THREE.MathUtils.radToDeg(theta2Radians);
        // Log the angles in degrees
        console.log(`Theta1: ${theta1Degrees.toFixed(2)} degrees`);
        console.log(`Theta2: ${theta2Degrees.toFixed(2)} degrees`);
    
        return { theta1: theta1Radians, theta2: theta2Radians };
    }
    armRotations = solveIK2D(point.x, point.y, boomLength, stickLength);

    arm1Joint.rotation.z = 0 - boomOffset; // armRotations.theta1 - boomOffset;
    arm2Joint.rotation.z = 0 - stickOffset; // armRotations.theta2 - stickOffset;

}

function onDocumentKeyDown(event) {
    const keyCode = event.which;
    let swingControl = 0, boomControl = 0, stickControl = 0, bucketControl = 0;

    switch (keyCode) {
        case 90: // Z
            swingControl = 0.1;
            break;
        case 88: // X
            swingControl = -0.1;
            break;
        case 87: // W
            boomControl = 0.1;
            break;
        case 83: // S
            boomControl = -0.1;
            break;
        case 81: // Q
            stickControl = 0.1;
            break;
        case 65: // A
            stickControl = -0.1;
            break;
        case 69: // E
            bucketControl = 0.1;
            break;
        case 68: // D
            bucketControl = -0.1;
            break;
    }

    if (excavator && excavator.modelLoaded) {
        excavator.updateExcavatorPose(boomControl, stickControl, bucketControl, swingControl);
    }
}



function createJoysticks() {
    // Create left joystick in the bottom left corner
    const leftOptions = {
        zone: document.getElementById('leftJoystickContainer'),
        mode: 'static',
        position: { left: '5%', bottom: '5%' },
        color: 'gray',
        size: 200  // Set the size of the joystick to 200 pixels. 
        // The range of output appears to be -100 to 100 for.
    };
    leftJoystick = nipplejs.create(leftOptions);

    leftJoystick.on('start end', function (evt, data) {
        leftJoystickData = { distance: 0, angle: { radian: 0 } }; // Reset joystick data when released
    }).on('move', function (evt, data) {
        leftJoystickData = data; // Update joystick data
    });

    // Create right joystick in the bottom right corner
    const rightOptions = {
        zone: document.getElementById('rightJoystickContainer'),
        mode: 'static',
        position: { right: '5%', bottom: '5%' },
        color: 'gray',
        size: 200  // Set the size of the joystick to 200 pixels
    };
    rightJoystick = nipplejs.create(rightOptions);

    rightJoystick.on('start end', function (evt, data) {
        rightJoystickData = { distance: 0, angle: { radian: 0 } }; // Reset joystick data when released
    }).on('move', function (evt, data) {
        rightJoystickData = data; // Update joystick data
    });
}

function updateGamepadControls() {
    if (!gamepad || !excavator || !excavator.modelLoaded) return;

    // Update gamepad status
    gamepad = navigator.getGamepads()[gamepad.index];

    // Debounce gridHelper visibility toggle when button 0 is pressed
    if (gamepad.buttons[0].pressed && !lastButton0State) {
        gridHelper.visible = !gridHelper.visible;
        console.log('toggle grid');

        excavator.traverse(function (child) {
            if (child.isMesh) {
                const shouldBeTransparent = gridHelper.visible && child.name !== 'BucketMain';
                child.material.transparent = shouldBeTransparent;
                child.material.opacity = shouldBeTransparent ? 0.2 : 1.0;
            }
        });
    }
    lastButton0State = gamepad.buttons[0].pressed;

    // Mapping gamepad sticks to the same control scheme as the on-screen joysticks
    let boomControl, stickControl, bucketControl, swingControl;
    if (controlMode === 'ISO') {
        // ISO control mapping according to new instructions
        swingControl = gamepad.axes[0]; // Left stick X-axis: Swing left or right
        stickControl = -gamepad.axes[1]; // Left stick Y-axis: Stick Boom (Dipper) away or close
        bucketControl = gamepad.axes[2]; // Right stick X-axis: Bucket curl in (closed) or out (dump)
        boomControl = gamepad.axes[3]; // Right stick Y-axis: Main Boom down or up
    } else if (controlMode === 'SAE') {
        // SAE control mapping according to new instructions
        swingControl = gamepad.axes[0]; // Left stick X-axis: Swing left or right
        boomControl = gamepad.axes[1]; // Left stick Y-axis: Main Boom down or up
        bucketControl = gamepad.axes[2]; // Right stick X-axis: Bucket curl in (closed) or out (dump)
        stickControl = -gamepad.axes[3]; // Right stick Y-axis: Stick Boom (Dipper) away or close
    }

    // Apply joystick sensitivity curve
    boomControl = joystickSensitivityCurve(boomControl * 100);
    stickControl = joystickSensitivityCurve(stickControl * 100);
    bucketControl = joystickSensitivityCurve(bucketControl * 100);
    swingControl = joystickSensitivityCurve(swingControl * 100, 'swing');

    // Update excavator controls based on gamepad input
    excavator.updateExcavatorPose(boomControl, stickControl, bucketControl, swingControl);
}


const controlPoints = [
    { input: 0, output: 0 },
    { input: 10, output: 0 },
    { input: 60, output: 40 },
    { input: 100, output: 100 }
];

const swingControlPoints = [
    { input: 0, output: 0 },
    { input: 50, output: 0 },
    { input: 80, output: 40 },
    { input: 100, output: 100 }
];

function joystickSensitivityCurve(value, controlType = 'default') {
    if (value === 0) return 0;

    let sign = Math.sign(value);
    value = Math.abs(value);
    let points = controlPoints; // Default control points

    if (controlType === 'swing') {
        console.log('swing');
        points = swingControlPoints;
    }

    for (let i = 0; i < points.length - 1; i++) {
        const start = points[i];
        const end = points[i + 1];
        if (value >= start.input && value <= end.input) {
            const t = (value - start.input) / (end.input - start.input);
            return sign * (start.output + t * (end.output - start.output));
        }
    }

    // If the value is beyond the range, clamp it
    return sign * (value >= 100 ? 100 : value);
}


function updateOnscreenJoystickControls() {
    // Left Joystick control for base and boom
    if (leftJoystickData.distance > 0) {
        const displacementXraw = leftJoystickData.distance * Math.cos(leftJoystickData.angle.radian);
        const displacementYraw = leftJoystickData.distance * Math.sin(leftJoystickData.angle.radian);
        const swingControl = joystickSensitivityCurve(displacementXraw, 'swing');
        const boomControl = joystickSensitivityCurve(displacementYraw);
        // Update excavator pose for swing and boom using the Excavator330B class
        excavator.updateExcavatorPose(boomControl, 0, 0, swingControl);
    }

    // Right Joystick control for stick and bucket
    if (rightJoystickData.distance > 0) {
        const displacementXraw = rightJoystickData.distance * Math.cos(rightJoystickData.angle.radian);
        const displacementYraw = rightJoystickData.distance * Math.sin(rightJoystickData.angle.radian);
        const bucketControl = joystickSensitivityCurve(displacementXraw);
        const stickControl = joystickSensitivityCurve(displacementYraw);
        // Update excavator pose for stick and bucket using the Excavator330B class
        excavator.updateExcavatorPose(0, stickControl, bucketControl, 0);
    }
}

// ========= Camera

// Initialize camera transition state
const cameraTransition = {
    active: false,
    startPosition: new THREE.Vector3(),
    endPosition: new THREE.Vector3(),
    startTime: 0,
    duration: 2000 // Duration of the transition in milliseconds
};

// This is for animation of the intro sequence.
const cameraPathAnimation = {
    active: false,
    path: new THREE.CatmullRomCurve3([]),
    startTime: 0,
    duration: 2000 // Duration of the transition in milliseconds
};

function animateCameraPath(path, lookPointPath) {
    // Prepare for smooth camera path animation
    cameraPathAnimation.active = true;
    cameraPathAnimation.path = path;
    cameraPathAnimation.lookPointPath = lookPointPath;
    cameraPathAnimation.startTime = Date.now();
    cameraPathAnimation.duration = introFlightDuration;
}


function animate() {
    requestAnimationFrame(animate);
    const currentTime = Date.now();
    const deltaTime = (currentTime - lastCallTime) / 1000; // Time in seconds since last call

    //controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true

    // Update gamepad controls if connected
    updateGamepadControls();

    // Update onscreen joystick controls
    updateOnscreenJoystickControls();



    // For gameified experience, call distance check.
    if (levelTargetTrajectory && levelTargetTrajectory.isPlaying()) {
        const currentPosition = new THREE.Vector3();
        bucketTipMarker.getWorldPosition(currentPosition);
        const currentOrientation = bucketTipMarker.quaternion;
        levelTargetTrajectory.targetTrajectory.comparePosition(currentPosition, currentOrientation);
        if (levelTargetTrajectory.targetTrajectory.isCompleted()) {
            levelTargetTrajectory.endGame();
        }
    }

    // ExcaVision support.
    //computeBucketVerticalFromZero(); // Call the function to compute and log the distance
    //computeLeftToDig();

    // Smooth camera trajectory for intro animation.
    if (cameraPathAnimation.active) {
        const transitionDelta = (currentTime - cameraPathAnimation.startTime) / cameraPathAnimation.duration;
        const easeInCubic = t => t * t * t;
        const easeOutCubic = t => --t * t * t + 1;
        let t;

        let previousPoint = camera.position.clone(); // Store the previous point
        if (transitionDelta < 0.5) {
            t = easeInCubic(transitionDelta * 2) / 2;
        } else if (transitionDelta < 1) {
            t = 0.5 + easeOutCubic((transitionDelta - 0.5) * 2) / 2;
        } else {
            t = 1;
            cameraPathAnimation.active = false;
        }
        const point = cameraPathAnimation.path.getPointAt(t);
        const lookPoint = cameraPathAnimation.lookPointPath.getPointAt(t);
        camera.position.copy(point);
        if (transitionDelta < 1) {
            controls.target.lerp(lookPoint, t);
        } else {
            controls.target.copy(lookPoint);
        }
    }
    controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true

    renderer.render(scene, camera);
}

function introSequence() {
    const textMeshBoundingBox = textMesh.geometry.boundingBox;
    const textMeshWidth = textMeshBoundingBox.max.x - textMeshBoundingBox.min.x;
    const textMeshHeight = textMeshBoundingBox.max.y - textMeshBoundingBox.min.y;
    const nPositionX = textMeshWidth * (4/12); // 'N' is the 4th character in 'TIME    N EARTH'
    const nPositionY = textMeshHeight; // Half the height. 
    const globalNPosition = textMesh.localToWorld(new THREE.Vector3(nPositionX, nPositionY, 0));
    // Add the text to the scene

    // Adjust the initial camera position to ensure the text takes up 80% of the viewport width.
    // Aspect where text fits well vertically is 1.24. This is the max. If it is lower then need to scale the distance.
    if (camera.aspect < 1.24) {
        const adjustmentRatio = 1.24 / camera.aspect;
        const adjustedCameraPosition = adjustmentRatio * -200; //camera.position.x;
        const adjustedTextPosition = adjustmentRatio * - 115; //textMesh.position.x;
        camera.position.set(adjustedCameraPosition, 0, 0);
        textMesh.position.x = adjustedTextPosition;
    } else {
        // Position the camera between the Earth and the Sun
        camera.position.set(0, 0, 30);
    }
    camera.lookAt(new THREE.Vector3(5, 0, 10)); // Logo Text Position.
    controls.target.set(0, 0, 0);
    

    textMesh.visible = true;

    setTimeout(function() {

        const startPostion = camera.position.clone();
        const endPosition = new THREE.Vector3(-5, 5, 10);
        const waypoint = startPostion.clone().lerp(endPosition, 0.5);
        const path = new THREE.CatmullRomCurve3([
            startPostion,
            waypoint,
            endPosition
        ]);

        // Create a linear path for the lookPointPath that starts at the (0,0,20) and ends at (0,0,0)
        const lookPointPath = new THREE.LineCurve3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(-2, 0, 0));
        //const lookPointPath = new THREE.LineCurve3(new THREE.Vector3(5, 0, 10), new THREE.Vector3(5, 0, 10));

        animateCameraPath(path, lookPointPath);

    }, introInitialPause);

    setTimeout(function() {
      excavatorMesh.visible = false;
      textMesh.visible = false;
    }, introInitialPause + introFlightDuration);
}
window.introSequence = introSequence;

/*
// For ExcaVision
function setupButtonEventHandlers() {
    document.getElementById('zeroButton').addEventListener('click', function() {
        if (bucketMain) {
            const bucketTipPosition = new THREE.Vector3();
            bucketTipMarker.getWorldPosition(bucketTipPosition);
            zeroReferenceLevel = bucketTipPosition.y;
            setDigDepth();
        }
    });

    document.getElementById('setDigDepthButton').addEventListener('click', setDigDepth);
}

function setDigDepth() {
    let digDepth = parseFloat(document.getElementById('digDepthInput').value);
    digDepth = isNaN(digDepth) ? 2.0 : digDepth;
    if (safetyBox && typeof zeroReferenceLevel !== 'undefined') {
        safetyBox.position.y = zeroReferenceLevel - digDepth;
    }
}


function computeBucketVerticalFromZero() {
    if (bucketMain) {
        const bucketTipPosition = new THREE.Vector3();
        bucketTipMarker.getWorldPosition(bucketTipPosition);
        //const distanceToGround = bucketTipPosition.y - groundPlane.position.y;
        const distanceToGround = bucketTipPosition.y - zeroReferenceLevel;
        document.getElementById('bucketPositionDisplay').innerText = `${distanceToGround.toFixed(2)} m`;
    }
}

function computeLeftToDig() {
    const digDepth = parseFloat(document.getElementById('digDepthInput').value);
    if (!isNaN(digDepth)) {
        const bucketTipPosition = new THREE.Vector3();
        if (bucketTipMarker) {
            bucketTipMarker.getWorldPosition(bucketTipPosition);
            const distanceToDig = bucketTipPosition.y - (zeroReferenceLevel - digDepth);
            const leftToDigDisplay = document.getElementById('leftToDigDisplay');
            leftToDigDisplay.innerText = `${distanceToDig.toFixed(2)} m`;
            if (distanceToDig < 0) {
                leftToDigDisplay.style.color = 'red';
            } else {
                leftToDigDisplay.style.color = 'black'; // or any default color
            }
        }
    }
}
*/