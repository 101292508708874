// This loads and updates the model for the PC120 excavator.
// This is the model from: 

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { ExcavatorBaseClass } from './ExcavatorBaseClass.js';
import ExcavatorGLB from 'url:./assets/models/PC120_Excavator_TK.glb'

export class ExcavatorPC120 extends ExcavatorBaseClass {
    constructor(scene) {
        super();
        this.scene = scene;
        this.excavator = null;
        this.undercarriage = null;
        this.torso = null;  // Also called House, Upper Strucure, Superstructure.
        this.torsoBone = null;
        this.boom = null;
        this.boomBone = null;
        this.stick = null;  // Sometimes called arm, which is a misnomer.
        this.stickBone = null;
        this.stick = null;
        this.bucketBone = null;
        this.bucketTip = null;
        this.controlMode = 'ISO'; // Default control mode
        this.modelLoaded = false;


    }

    loadModel() {
        const loader = new GLTFLoader();
        loader.load(ExcavatorGLB, (gltf) => {
            this.excavator = gltf.scene;
            //torso = this.excavator.getObjectByName('Base1'); // Mesh excavator body.
            this.torsoBone = this.excavator.getObjectByName('TurnAxis'); // Base bone.
            //boom = this.excavator.getObjectByName('ArmA'); // Mesh boom
            this.boomBone = this.excavator.getObjectByName('BoomAxis'); // Bone
            //stick = this.excavator.getObjectByName('Arm_B'); // Mesh stick
            this.stickBone = this.excavator.getObjectByName('ArmAxis'); // Bone
            //bucket = this.excavator.getObjectByName('BucketMain'); // Mesh
            this.bucketBone = this.excavator.getObjectByName('BucketAxis'); // Bone
            this.bucketTipMarker = this.excavator.getObjectByName('BucketAxis'); // Object.


            // Supporting hydrolic cylinders and linkages.
            this.armLinkageAxis = this.excavator.getObjectByName("ArmLinkageAxis");
            this.bucketLinkageAxis = this.excavator.getObjectByName("BucketLinkageAxis");

            this.boomCylinderRightAxis1 = this.excavator.getObjectByName("BoomCylinderRightAxis1");
            this.boomCylinderRight1Target = this.excavator.getObjectByName("BoomCylinderRight1Target");

            this.boomCylinderRightAxis2 = this.excavator.getObjectByName("BoomCylinderRightAxis2");
            this.boomCylinderRight2Target = this.excavator.getObjectByName("BoomCylinderRight2Target");

            this.boomCylinderLeftAxis1 = this.excavator.getObjectByName("BoomCylinderLeftAxis1");
            this.boomCylinderLeft1Target = this.excavator.getObjectByName("BoomCylinderLeft1Target");

            this.boomCylinderLeftAxis2 = this.excavator.getObjectByName("BoomCylinderLeftAxis2");
            this.boomCylinderLeft2Target = this.excavator.getObjectByName("BoomCylinderLeft2Target");

            this.armCylinderAxis1 = this.excavator.getObjectByName("ArmCylinderAxis1");
            this.armCylinder1Target = this.excavator.getObjectByName("ArmCylinder1Target");

            this.armCylinderAxis2 = this.excavator.getObjectByName("ArmCylinderAxis2");
            this.armCylinder2Target = this.excavator.getObjectByName("ArmCylinder2Target");

            this.bucketCylinderAxis1 = this.excavator.getObjectByName("BucketCylinderAxis1");
            this.bucketCylinder1Target = this.excavator.getObjectByName("BucketCylinder1Target");

            this.bucketCylinderAxis2 = this.excavator.getObjectByName("BucketCylinderAxis2");
            this.bucketCylinder2Target = this.excavator.getObjectByName("BucketCylinder2Target");

            this.armLinkageTarget = this.excavator.getObjectByName("ArmLinkageTarget");

            this.hookMainAxis = this.excavator.getObjectByName("HookMainAxis");

            // Hard coded extem angles of the arm segments:



            this.scene.add(this.excavator);
            this.modelLoaded = true;  // Animation loop starts before models are fully loaded.
        });
    }

    updateExcavatorPose(boomControl, stickControl, bucketControl, swingControl) {
        if (Math.abs(swingControl) > 0) {
            // Use the swingControl to control the base rotation
            let newRotationYBase = this.torsoBone.rotation.y + 0.0005 * -swingControl;
            this.torsoBone.rotation.y = newRotationYBase;
        }

        if (Math.abs(boomControl) > 0) {
            // Create a quaternion representing the rotation around the Z-axis
            const quaternion = new THREE.Quaternion();
            quaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), 0.0005 * boomControl);

            // Apply the quaternion to the current rotation
            this.boomBone.quaternion.multiplyQuaternions(this.boomBone.quaternion, quaternion);

            this.orientBoomCylinder();

        }

        if (Math.abs(stickControl) > 0) {
            // Use the stickControl to control Arm2 using quaternions
            const stickQuaternion = new THREE.Quaternion();
            stickQuaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), 0.0005 * stickControl);
            this.stickBone.quaternion.multiplyQuaternions(this.stickBone.quaternion, stickQuaternion);
            this.orientArmCylinder();

        }

        if (Math.abs(bucketControl) > 0) {
            // Use the bucketControl to control the bucket rotation using quaternions
            const bucketQuaternion = new THREE.Quaternion();
            bucketQuaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), 0.001 * -bucketControl);
            this.bucketBone.quaternion.multiplyQuaternions(this.bucketBone.quaternion, bucketQuaternion);

            this.orientBucketCylinder();
        }
    }

    switchControlModeTo(mode) {
        this.controlMode = mode;
    }

    setZeroReferenceLevelFromBucket() {
        if (this.bucketTipMarker) {
            const bucketTipPosition = new THREE.Vector3();
            this.bucketTipMarker.getWorldPosition(bucketTipPosition);
            this.zeroReferenceLevel = bucketTipPosition.y;
        }
    }

    // Generic function that takes the hydraulic cylinder, i.e., Cylinder Tube and Piston Rod and orients correctly.
    orientHydraulicCylinder(cylinder1, cylinder1Target, cylinder2, cylinder2Target) {
        const cylinder1TargetWorldPos = new THREE.Vector3();
        cylinder1Target.getWorldPosition(cylinder1TargetWorldPos);
        // Logging the transformation matrix used by lookAt
        cylinder1.lookAt(cylinder1TargetWorldPos);
        cylinder1.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2);

        const cylinder2TargetWorldPos = new THREE.Vector3();
        cylinder2Target.getWorldPosition(cylinder2TargetWorldPos);
        //cylinder2.up = new THREE.Vector3(1, 0, 0);
        cylinder2.lookAt(cylinder2TargetWorldPos);
        cylinder2.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2);
       
    }

    orientHydraulicCylinderNew(cylinder1, cylinder1Target, cylinder2, cylinder2Target) {

        this.lookAtLimitedToXAxis(cylinder1, cylinder1Target);
        //cylinder1.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2);

        this.lookAtLimitedToXAxis(cylinder2, cylinder2Target);
        //cylinder2.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2);
    }

    orientLinkage(linkage, linkageTarget) {
        const linkageTargetWorldPos = new THREE.Vector3();
        linkageTarget.getWorldPosition(linkageTargetWorldPos);
        linkage.up = linkage.localToWorld(new THREE.Vector3(1, 0, 0)).sub(linkage.position).normalize();
        linkage.lookAt(linkageTargetWorldPos);
        const linkageQuaternion = new THREE.Quaternion();
        linkageQuaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI / 2);
        linkage.quaternion.multiplyQuaternions(linkage.quaternion, linkageQuaternion);
    }

    orientArmCylinder() {
        this.orientHydraulicCylinder(this.armCylinderAxis1, this.armCylinder1Target, this.armCylinderAxis2, this.armCylinder2Target);
    }

    orientBoomCylinder() {
        this.orientHydraulicCylinder(this.boomCylinderRightAxis1, this.boomCylinderRight1Target, this.boomCylinderRightAxis2, this.boomCylinderRight2Target);
        this.orientHydraulicCylinder(this.boomCylinderLeftAxis1, this.boomCylinderLeft1Target, this.boomCylinderLeftAxis2, this.boomCylinderLeft2Target);
    }

    orientBucketCylinder() {
        this.orientLinkage(this.armLinkageAxis, this.armLinkageTarget);
        this.orientLinkage(this.bucketLinkageAxis, this.bucketCylinder1Target);
        this.orientHydraulicCylinder(this.bucketCylinderAxis1, this.bucketCylinder1Target, this.bucketCylinderAxis2, this.bucketCylinder2Target);
    }

}
