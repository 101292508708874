import * as THREE from 'three';

class TrajectoryTracker {
    constructor(targetPoints, orientationTargets = []) {
        this.targetPoints = targetPoints.map(p => new THREE.Vector3(p.x, p.y, p.z));
        this.orientationTargets = orientationTargets.map(o => new THREE.Quaternion(o.x, o.y, o.z, o.w));
        this.currentIndex = 0;
        this.startTime = null;
        this.endTime = null;
        this.totalDistance = 0;
        this.totalTime = 0;
        this.matchedPoints = 0;
        this.sphereMeshes = []; // Store sphere meshes to change color later
        this.activeSphereColor = new THREE.Color(0xff0000); // Color for the active target sphere
        this.inactiveSphereColor = new THREE.Color(0x808080); // Color for the inactive target spheres
        this.completedSphereColor = new THREE.Color(0x00ff00); // Color for the completed target spheres
    }

    start() {
        this.startTime = performance.now();
        this.currentIndex = 0;
        this.totalDistance = 0;
        this.totalTime = 0;
        this.matchedPoints = 0;
    }

    comparePosition(currentPosition, currentOrientation = null) {
        if (this.currentIndex >= this.targetPoints.length) return null;

        const targetPoint = this.targetPoints[this.currentIndex];
        const distance = currentPosition.distanceTo(targetPoint);

        let orientationError = 0;
        if (currentOrientation && this.orientationTargets.length > 0) {
            const targetOrientation = this.orientationTargets[this.currentIndex];
            const deltaQuat = targetOrientation.clone().inverse().multiply(currentOrientation);
            orientationError = deltaQuat.angleTo(new THREE.Quaternion());
        }

        console.log(`Distance to target: ${distance.toFixed(2)}, Orientation error: ${orientationError.toFixed(2)}`);

        if (distance < 0.2 /*&& orientationError < 0.1*/) { // Adjust thresholds as needed
            this.totalDistance += distance;
            this.matchedPoints++;
            // Change the color of the sphere to green when the target is hit
            if (this.sphereMeshes[this.currentIndex]) {
                this.sphereMeshes[this.currentIndex].material.color.set(this.completedSphereColor);
            }
            this.currentIndex++;
            // Change the color of the next sphere to red to indicate the next target
            if (this.currentIndex < this.targetPoints.length && this.sphereMeshes[this.currentIndex]) {
                this.sphereMeshes[this.currentIndex].material.color.set(this.activeSphereColor);
            }

            //if (this.currentIndex === this.targetPoints.length) {
                this.endTime = performance.now();
                this.totalTime = this.endTime - this.startTime;
            //}
        }

        return { distance, orientationError };
    }

    getScore() {
        const accuracyScore = (this.matchedPoints / this.targetPoints.length) * 100;
        const timePenalty = Math.max(0, (this.totalTime / 1000) - this.targetPoints.length * 5);
        const finalScore = accuracyScore - timePenalty;
        const hitCount = this.matchedPoints; // Count of how many points have been hit

        return {
            accuracyScore: accuracyScore,
            totalDistance: this.totalDistance,
            totalTime: this.totalTime / 1000,
            finalScore: Math.max(0, finalScore),
            hitCount: hitCount // Include the hit count in the score object
        };
    }

    isCompleted() {
        return this.currentIndex >= 1;// this.targetPoints.length;
    }

    generateLinearTrajectory(startPoint, endPoint, numPoints) {
        if (numPoints < 2) {
            throw new Error('Number of points must be at least 2 to form a linear trajectory.');
        }

        this.targetPoints = [];
        this.orientationTargets = []; // Assuming orientation targets are not needed for linear trajectory
        this.sphereMeshes = []; // Reset sphere meshes for the new trajectory

        const stepVector = new THREE.Vector3().subVectors(endPoint, startPoint).divideScalar(numPoints - 1);

        for (let i = 0; i < numPoints; i++) {
            const point = new THREE.Vector3().addVectors(startPoint, stepVector.clone().multiplyScalar(i));
            this.targetPoints.push(point);
        }
    }

    addTrajectoryToScene(scene) {
        const sphereGeometry = new THREE.SphereGeometry(0.2, 32, 32);
        const sphereMaterial = new THREE.MeshStandardMaterial({ color: this.inactiveSphereColor, metalness: 0.5, roughness: 0.5 });

        this.targetPoints.forEach((point, index) => {
            const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial.clone()); // Clone material for each mesh
            sphereMesh.position.copy(point);
            scene.add(sphereMesh);
            this.sphereMeshes.push(sphereMesh); // Store the mesh to change color later
            // Set the color of the first target sphere to red
            if (index === 0) {
                sphereMesh.material.color.set(this.activeSphereColor);
            }
        });
    }

    removeTrajectoryFromScene(scene) {
        this.sphereMeshes.forEach((sphereMesh) => {
            scene.remove(sphereMesh);
        });
        this.sphereMeshes = []; // Clear the array of sphere meshes
    }
}

export default TrajectoryTracker;
