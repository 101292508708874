
export class ExcavatorBaseClass {
    constructor(name) {
        if (new.target === ExcavatorBaseClass) {
            throw new TypeError("Cannot construct ExcavatorAbstractClass instances directly");
        }
        this.name = name;
    }

    loadModel() {
        throw new Error("Method 'loadModel()' must be implemented.");
    }

    deleteExcavator() {
        if (this.excavator) {
            this.scene.remove(this.excavator);
            this.excavator.traverse((object) => {
                if (object.isMesh) {
                    object.geometry.dispose();
                    if (object.material.isMaterial) {
                        object.material.dispose();
                    } else {
                        // In case of multi-materials, dispose each of them
                        for (const material of object.material) {
                            material.dispose();
                        }
                    }
                }
            });
            this.excavator = null;
            console.log('Excavator deleted from scene');
        }
    }

    setupGamepad() {
        throw new Error("Method 'setupGamepad()' must be implemented.");
    }

    updateGamepadControls() {
        throw new Error("Method 'updateGamepadControls()' must be implemented.");
    }

    updateExcavatorControls(boomControl, stickControl, bucketControl, swingControl) {
        throw new Error("Method 'updateExcavatorControls()' must be implemented.");
    }

    switchControlModeTo(mode) {
        throw new Error("Method 'switchControlModeTo()' must be implemented.");
    }

    setZeroReferenceLevelFromBucket() {
        throw new Error("Method 'setZeroReferenceLevelFromBucket()' must be implemented.");
    }

    computeAndLogLengths() {
        throw new Error("Method 'computeAndLogLengths()' must be implemented.");
    }
}